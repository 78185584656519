import dynamic from 'next/dynamic';
import Input from '../Hooks/Input';
import { MailIcon } from '@heroicons/react/solid';
import { onLeadError, getAdsParameters } from '../../util/util';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Dropdown from '../Hooks/Dropdown';
import FormBanner from '../Promos/FormBanner';
import { createSessionMetadata } from '../../apiClient/operations/sessionOperations';

const FormCard = ({ promo, details, signupLoading, setSignupLoading, title, buttonLabel, page, hidden = false }) => {
    const promoForm = details?.show_form_banner != undefined ? details?.show_form_banner : true
    const RouteLoader = dynamic(() => import('../Common/RouteLoader'))
    const [submited, setSubmited] = useState(false);
    const [referrer, setReferrer] = useState('');
    const [error, setError] = useState(null);
    const buttonEmailRef = useRef();
    const buttonNameRef = useRef();
    const buttonPhoneRef = useRef();
    const box = useRef();
    const formBox = useRef();
    const paramsCookieName = "fixat_utm_params";
    const router = useRouter();

    const form = useForm();
    const {
        register,
        handleSubmit,
        reset,
        watch,
        trigger,
        formState: { errors, dirtyFields },
    } = form;

    function isFieldValid(form, field) {
        return form.formState.dirtyFields[field] && !form.formState.errors[field] && submited
    }

    useEffect(() => {
        setReferrer(document?.referrer || '');
    }, []);

    const wName = watch('name')
    useEffect(() => {
        if (dirtyFields.name === true && submited) {
            buttonNameRef.current.click()
        }

    }, [wName, dirtyFields.name, submited]);

    const wPhone = watch('phone');
    useEffect(() => {
        if (dirtyFields.phone === true && submited) {
            buttonPhoneRef.current.click()
        }

    }, [wPhone, dirtyFields.phone, submited]);

    const wEmail = watch('email');
    useEffect(() => {
        if (dirtyFields.email === true && submited) {
            buttonEmailRef.current.click()
        }

    }, [wEmail, dirtyFields.email, submited]);


    const onRouteChangeComplete = loading => {
        setSignupLoading(loading);
        reset();
    }

    useEffect(() => {
        setReferrer(document?.referrer || '');
    }, []);

    const scrollFunction = (ref) => {
        if (!ref.current) return;
        ref.current.scrollIntoView({ behavior: "smooth" });
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        const { name, email, phone, bill, occupancy } = data;
        const is_billing = parseInt(bill)
        if (error) { setError(null) }
        setSignupLoading(true);
        const campaign_ads = getAdsParameters(paramsCookieName, router);
        const source = campaign_ads?.utm_source || "rforganic";
        campaign_ads['referrer'] = referrer;
        createSessionMetadata(name, email, `+52${phone.replace(/\s+/g, '')}`, source, campaign_ads, is_billing, occupancy).then(response => {
            if (is_billing != 0) {
                window.gtag('event', 'subscribe');
                window._fbq.push('track', 'Subscribe');
                window.ttq.push('track', 'Subscribe');
            }
            localStorage.setItem('lead', JSON.stringify(response));
            router.push(page);
        }).catch(error => {
            const { message, loadingSignup } = onLeadError(error, data)
            if (window.innerWidth < 600) {
                scrollFunction(formBox)
            } else {
                scrollFunction(box)
            }
            setError(message);
            setSignupLoading(loadingSignup);
        });
    }

    const showErrors = () => {
        setSubmited(true)
        trigger('name')
        trigger('email')
        trigger('phone')
    }

    return (
        <div className={`${hidden && 'md:hidden'} mmd:w-full mmd:max-w-none max-w-[550px] relative rounded-2xl bg-white  z-10 mmd:mx-4 `} ref={box}>
            {(promo && promoForm) && <FormBanner />}
            <div className={` max-w-[538px] mmd:justify-center w-full flex-1  flex flex-col py-3 px-6 box-border items-start justify-start gap-1  text-center text-sm h-fit mmd:items-center  ssm:h-full font-text-sm-leading-5-font-normal mmd:gap-5 mmd:min-w-full mmd:flex-[unset] mmd:self-stretch ssm:flex-[unset] ssm:self-stretch ssm:gap-4 z-10`} id='form'>
                <div className="self-stretch relative items-center flex justify-center">
                    <p className={`${(promo && promoForm) ? 'mt-2' : 'mt-8'} text-2xl  mmd:mt-4 ssm:max-w-[300px] font-fredoka-one text-steelblue-500 mmd:max-w-none`}>
                        {title}
                    </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className=" w-full flex flex-col items-center justify-start gap-[12px] text-left font-quicksand" ref={formBox}>
                    <Input
                        label='Correo electrónico'
                        type='email'
                        autofocus
                        id='email'
                        name='email'
                        placeholder={'Ej. mail@correo.mx'}
                        needed
                        leftIcon={<MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                        pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}
                        minLength={6}
                        register={register}

                        errorsText={['Ingresa tu correo electrónico, por favor.', 'El correo electónico no es válido', 'El correo electrónico debe tener mínimo 11 caracteres']}
                        isValid={isFieldValid(form, 'email')}
                        errors={errors}
                    />
                    <Input
                        label='Nombre completo'
                        type='text'
                        id='name'
                        name='name'
                        placeholder={'Ej. María Félix García Sánchez'}
                        needed
                        pattern={/^[A-Za-zÑñÁÉÍÓÚáéíóúÜü,. -']{1,45}$|^$/}
                        minLength={8}
                        register={register}
                        errorsText={['Ingresa tu nombre completo, por favor.', 'El nombre completo no es válido, no ingreses números', 'El nombre completo debe tener 8 caracteres']}
                        isValid={isFieldValid(form, 'name')}
                        errors={errors}
                    />
                    <Input
                        label='Número de WhatsApp'
                        type='tel'
                        id='phone'
                        name='phone'
                        placeholder={'5559876543'}
                        needed
                        pattern={/^[0-9]+$/}
                        minLength={10}
                        maxLength={10}
                        register={register}
                        errorsText={['Ingresa tu número de teléfono, por favor.', 'El número de teléfono no es válido, ingresa solo números', 'El número de telefono debe tener 10 caracteres']}
                        isValid={isFieldValid(form, 'phone')}
                        errors={errors}
                    />
                    <Input
                        label='¿A qué te dedicas? '
                        type='text'
                        id='occupancy'
                        name='occupancy'
                        placeholder={'Agrega tu actividad profesional'}
                        needed
                        pattern={/^[A-Za-zÑñÁÉÍÓÚáéíóúÜü,. -']{5,45}$|^$/}
                        minLength={5}
                        register={register}
                        errorsText={['Ingresa tu ocupación, por favor.', 'La ocupación no es válida', 'La ocupación debe tener al menos 5 carácteres']}
                        isValid={isFieldValid(form, 'occupancy')}
                        errors={errors}
                    />
                    <Dropdown
                        label='¿Generas facturas a tus clientes?'
                        id='bill'
                        name='bill'
                        needed
                        isValid={isFieldValid(form, 'bill')}
                        register={register}
                        errors={errors}
                        options={[{ label: "Sí facturo", value: 1 }, { label: "No facturo", value: 0 }, { label: "No, pero me urge hacer una factura", value: 2 }]}
                    />
                    <button className='hidden' type='button' ref={buttonNameRef} onClick={() => { trigger('name') }}>
                    </button>
                    <button className='hidden' type='button' ref={buttonPhoneRef} onClick={() => { trigger('phone') }}>
                    </button>
                    <button className='hidden' type='button' ref={buttonEmailRef} onClick={() => { trigger('email') }}>
                    </button>
                    <button className="text-blue-new-button-text md:hover:bg-hover-orange bg-button-orange mt-1 cursor-pointer [border:none] py-4 px-[30px] self-stretch rounded-81xl flex flex-row items-center justify-center ssm:items-center ssm:justify-center" type='submit' onClick={showErrors}>
                        {signupLoading ? (
                            <RouteLoader setLoading={onRouteChangeComplete} />
                        )
                            : (
                                <b className=" relative text-lg ssm:text-base font-quicksand font-bold text-left ssm:text-center">{buttonLabel}</b>)
                        }
                    </button>
                    <div id='error'>
                        {error &&
                            <div className="flex w-full mt-3">
                                <span className="w-full help-block-error">{error}</span>
                            </div>
                        }
                    </div>
                </form>
                <div className="w-full mt-2 mmd:mt-1 px-4 flex flex-row items-center justify-center gap-6 text-left text-base text-negro-textos ssm:w-full">
                    <div className="w-fit"><p className='text-base font-medium font-quicksand'>{`Tus datos personales 100% protegidos`}</p></div>
                    <div className="relative w-[73px] h-14 object-cover">
                        <Image
                            loading="lazy"
                            layout='fill'
                            alt="lets_encrypted"
                            src="https://cdn.fixat.mx/assets/home/img/lets_encrypt.png"
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default FormCard;
